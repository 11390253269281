import { render, staticRenderFns } from "./UploadModalButton.vue?vue&type=template&id=4665e642"
import script from "./UploadModalButton.vue?vue&type=script&lang=js"
export * from "./UploadModalButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UploadFilesList: require('/codebuild/output/src1947065313/src/client/components/uploadButton/UploadFilesList.vue').default,UploadButton: require('/codebuild/output/src1947065313/src/client/components/uploadButton/UploadButton.vue').default,UploadArtworksList: require('/codebuild/output/src1947065313/src/client/components/uploadButton/UploadArtworksList.vue').default})
